import React, { useState, useEffect, useContext } from 'react';
import Tapbutton from './images/boton.png';
import Telegram from './images/telegrama.png';
import Twitter from './images/gorjeo.png';
import Youtube from './images/youtube.png';
import En from './images/estados-unidos.png';
import Es from './images/espana.png';
import Jp from './images/japon.png';
import Hi from './images/india.png';
import Fr from './images/francia.png';
import Ch from './images/porcelana.png';
import Ar from './images/bandera.png';
import Sz from './images/suiza.png';
import De from './images/alemania.png';
import Bs from './images/brasil.png';
import Ko from './images/corea-del-sur.png';
import Ru from './images/rusia.png';
import './App.css';
import envio from './php/send.php';
import { FormattedMessage } from 'react-intl';
import { LangContext } from './context/langContext';
import { Fade } from "react-reveal";
import Disclaimer from './Disclaimer';

const useCounter = () => {
  const initialCounter = parseInt(localStorage.getItem('counter')) || 0;
  const [counter, setCounter] = useState(initialCounter);

  useEffect(() => {
    const increment = parseInt(localStorage.getItem('increment')) || 0;
    if (increment > 0) {
      const newCounter = counter + increment;
      setCounter(newCounter);
      localStorage.setItem('counter', newCounter.toString());
      localStorage.removeItem('increment');
    }
  }, [counter]);

  const increase = () => {
    if (counter < 100000000) {
      const newCounter = counter + 50;
      setCounter(newCounter);
      localStorage.setItem('counter', newCounter.toString());
    }
  };

  return {
    counter,
    increase,
  };
};

function isValidEthereumAddress(address) {
  return /^(0x)?[0-9a-f]{40}$/i.test(address);
}

function App() {
  const { establecerLenguaje } = useContext(LangContext);
  const { counter, increase } = useCounter();
  const [clickedButtons, setClickedButtons] = useState({
    telegram1: localStorage.getItem('telegram1Clicked') === 'true',
    telegram2: localStorage.getItem('telegram2Clicked') === 'true',
    twitter: localStorage.getItem('twitterClicked') === 'true',
    youtube: localStorage.getItem('youtubeClicked') === 'true',
    facebook: localStorage.getItem('facebookClicked') === 'true',
  });
  const [formData, setFormData] = useState({
    telegramId: '',
    walletBep20: '',
  });
  const [formErrors, setFormErrors] = useState({
    telegramId: false,
    walletBep20: false,
  });
  const [setFormSubmitted] = useState(false);

  const handleLinkClick = (incrementAmount, platform) => {
    if (!clickedButtons[platform]) {
      localStorage.setItem('increment', incrementAmount.toString());
      localStorage.setItem(`${platform}Clicked`, 'true');
      setClickedButtons((prevState) => ({
        ...prevState,
        [platform]: true,
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {
      telegramId: !formData.telegramId,
      walletBep20: !isValidEthereumAddress(formData.walletBep20),
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error);
  };

  const handleSubmit = (e) => {
    if (!validateForm()) {
      e.preventDefault();
    } else {
      setFormSubmitted(true);
    }
  };

  return (
    <div className="profiles-container">
      <select id="miSelect" className="miSelect" onChange={(e) => establecerLenguaje(e.target.value)}>
        <option value="es-MX" data-image={Es}>Español</option>
        <option value="en-US" data-image={En}>English</option>
        <option value="ja-JP" data-image={Jp}>日本語</option>
        <option value="fr-FR" data-image={Fr}>Français</option>
        <option value="pt-PT" data-image={Bs}>Português</option>
        <option value="ko-KO" data-image={Ko}>한국어</option>
        <option value="zh-CN" data-image={Ch}>中文</option>
        <option value="nl-NL" data-image={Sz}>Nederlands</option>
        <option value="ar-AR" data-image={Ar}>العربية</option>
        <option value="hi-HI" data-image={Hi}>हिन्दी</option>
        <option value="de-DE" data-image={De}>Deutsch</option>
        <option value="ru-RU" data-image={Ru}>RU</option>
      </select>
      <div className="counter-display">{counter}</div>
      <div className="button-container">
        <button className="tap-button" onClick={increase}>
          <Fade bottom>
            <img src={Tapbutton} alt="Tap Button" />
          </Fade>
        </button>
        <div className="botonesReferidos">
          {!clickedButtons.telegram1 && (
            <div className="containerref1">
              <p className="letras"><FormattedMessage id="Bot.tg1" defaultMessage="1,500,000 de puntos por seguirnos en:" /></p>
              <a href="https://t.me/SOCIALISM_ADS" target="_blank" rel="noopener noreferrer">
                <button className="ref-button" onClick={() => handleLinkClick(1500000, 'telegram1')} disabled={clickedButtons.telegram1}>
                  <Fade bottom>
                    <img src={Telegram} alt="Telegram Follow 1" />
                  </Fade>
                </button>
              </a>
            </div>
          )}
          {!clickedButtons.telegram2 && (
            <div className="containerref2">
              <p className="letras"><FormattedMessage id="Bot.tg1" defaultMessage="1,500,000 de puntos por seguirnos en:" /></p>
              <a href="https://t.me/Socialism_Coin" target="_blank" rel="noopener noreferrer">
                <button className="ref-button" onClick={() => handleLinkClick(1500000, 'telegram2')} disabled={clickedButtons.telegram2}>
                  <Fade bottom>
                    <img src={Telegram} alt="Telegram Follow 2" />
                  </Fade>
                </button>
              </a>
            </div>
          )}
          {!clickedButtons.twitter && (
            <div className="containerref3">
              <p className="letras"><FormattedMessage id="Bot.tg1" defaultMessage="1,500,000 de puntos por seguirnos en:" /></p>
              <a href="https://x.com/SocialismCoin" target="_blank" rel="noopener noreferrer">
                <button className="ref-button" onClick={() => handleLinkClick(1500000, 'twitter')} disabled={clickedButtons.twitter}>
                  <Fade bottom>
                    <img src={Twitter} alt="Twitter Follow" />
                  </Fade>
                </button>
              </a>
            </div>
          )}
          {!clickedButtons.youtube && (
            <div className="containerref4">
              <p className="letras"><FormattedMessage id="Bot.tg1" defaultMessage="1,500,000 de puntos por seguirnos en:" /></p>
              <a href="https://www.youtube.com/@SocialismCoin" target="_blank" rel="noopener noreferrer">
                <button className="ref-button" onClick={() => handleLinkClick(1500000, 'youtube')} disabled={clickedButtons.youtube}>
                  <Fade bottom>
                    <img src={Youtube} alt="YouTube Follow" />
                  </Fade>
                </button>
              </a>
            </div>
          )}
        </div>
      </div>
      {counter >= 10000000 && (
        <form className="post-form" method="POST" action={envio} onSubmit={handleSubmit}>
          <div>
            <label><FormattedMessage id="Bot.tgID" defaultMessage="ID de Telegram:" /></label>
            <input
              type="text"
              name="telegramId"
              value={formData.telegramId}
              onChange={handleInputChange}
              required
              className={formErrors.telegramId ? 'error' : ''}
            />
            {formErrors.telegramId && <span className="error-message"><FormattedMessage id="Bot.requiered" defaultMessage="Este campo es obligatorio." /></span>}
          </div>
          <div>
            <label><FormattedMessage id="Bot.Wallet" defaultMessage="Dirección BEP20:" /></label>
            <input
              type="text"
              name="walletBep20"
              value={formData.walletBep20}
              onChange={handleInputChange}
              required
              className={formErrors.walletBep20 ? 'error' : ''}
            />
            {formErrors.walletBep20 && <span className="error-message"><FormattedMessage id="Bot.requiered2" defaultMessage="dirección invalida." /></span>}
          </div>
          <button type="submit"><FormattedMessage id="Bot.Enviar" defaultMessage="Enviar" /></button>
        </form>
      )}
      <Disclaimer />
    </div>
  );
}

export default App;
