// langContext.js
import React, { useState } from "react";
import MensajesIngles from './../lang/en-US.json';
import MensajesEspañol from './../lang/es-MX.json';
import MensajesJapones from './../lang/ja-JP.json';
import MensajesFrances from './../lang/fr-FR.json';
import MensajesPortugues from './../lang/pt-PT.json';
import MensajesCoreano from './../lang/ko-KO.json';
import MensajesChino from './../lang/zh-CN.json';
import MensajesNeerlandes from './../lang/nl-NL.json';
import MensajesArabe from './../lang/ar-AR.json';
import MensajesHindu from './../lang/hi-HI.json';
import MensajesAleman from './../lang/de-DE.json';
import MensajesRuso from './../lang/ru-RU.json';
import { IntlProvider } from 'react-intl';

const LangContext = React.createContext();

const LangProvider = ({ children }) => {
    let localPorDefecto = 'es-MX';
    let mensajesPorDefecto = MensajesEspañol;
    const lang = localStorage.getItem('lang');

    if (lang) {
        localPorDefecto = lang;
        switch (lang) {
            case 'es-MX':
                mensajesPorDefecto = MensajesEspañol;
                break;
            case 'en-US':
                mensajesPorDefecto = MensajesIngles;
                break;
            case 'ja-JP':
                mensajesPorDefecto = MensajesJapones;
                break;
            case 'fr-FR':
                mensajesPorDefecto = MensajesFrances;
                break;
            case 'pt-PT':
                mensajesPorDefecto = MensajesPortugues;
                break;
            case 'ko-KO':
                mensajesPorDefecto = MensajesCoreano;
                break;
            case 'zh-CN':
                mensajesPorDefecto = MensajesChino;
                break;
            case 'nl-NL':
                mensajesPorDefecto = MensajesNeerlandes;
                break;
            case 'ar-AR':
                mensajesPorDefecto = MensajesArabe;
                break;
            case 'hi-HI':
                mensajesPorDefecto = MensajesHindu;
                break;
            case 'de-DE':
                mensajesPorDefecto = MensajesAleman;
                break;
                case 'ru-RU':
                    mensajesPorDefecto = MensajesRuso;
                    break;
            default:
                mensajesPorDefecto = MensajesEspañol;
                localPorDefecto = 'es-MX';
        }
    }

    const [mensajes, establecerMensajes] = useState(mensajesPorDefecto);
    const [locale, establecerLocale] = useState(localPorDefecto);

    const establecerLenguaje = (lenguaje) => {
        switch (lenguaje) {
            case 'en-US':
                establecerMensajes(MensajesIngles);
                establecerLocale('en-US');
                localStorage.setItem('lang', 'en-US');
                break;
            case 'es-MX':
                establecerMensajes(MensajesEspañol);
                establecerLocale('es-MX');
                localStorage.setItem('lang', 'es-MX');
                break;
            case 'ja-JP':
                establecerMensajes(MensajesJapones);
                establecerLocale('ja-JP');
                localStorage.setItem('lang', 'ja-JP');
                break;
            case 'fr-FR':
                establecerMensajes(MensajesFrances);
                establecerLocale('fr-FR');
                localStorage.setItem('lang', 'fr-FR');
                break;
            case 'pt-PT':
                establecerMensajes(MensajesPortugues);
                establecerLocale('pt-PT');
                localStorage.setItem('lang', 'pt-PT');
                break;
            case 'ko-KO':
                establecerMensajes(MensajesCoreano);
                establecerLocale('ko-KO');
                localStorage.setItem('lang', 'ko-KO');
                break;
            case 'zh-CN':
                establecerMensajes(MensajesChino);
                establecerLocale('zh-CN');
                localStorage.setItem('lang', 'zh-CN');
                break;
            case 'nl-NL':
                establecerMensajes(MensajesNeerlandes);
                establecerLocale('nl-NL');
                localStorage.setItem('lang', 'nl-NL');
                break;
            case 'ar-AR':
                establecerMensajes(MensajesArabe);
                establecerLocale('ar-AR');
                localStorage.setItem('lang', 'ar-AR');
                break;
            case 'hi-HI':
                establecerMensajes(MensajesHindu);
                establecerLocale('hi-HI');
                localStorage.setItem('lang', 'hi-HI');
                break;
            case 'de-DE':
                establecerMensajes(MensajesAleman);
                establecerLocale('de-DE');
                localStorage.setItem('lang', 'de-DE');
                break;
            case 'ru-RU':
                    establecerMensajes(MensajesRuso);
                    establecerLocale('ru-RU');
                    localStorage.setItem('lang', 'ru-RU');
                    break;
            default:
                establecerMensajes(MensajesEspañol);
                establecerLocale('es-MX');
                localStorage.setItem('lang', 'es-MX');
        }
    };

    return (
        <LangContext.Provider value={{ establecerLenguaje }}>
            <IntlProvider locale={locale} messages={mensajes}>
                {children}
            </IntlProvider>
        </LangContext.Provider>
    );
};

export { LangProvider, LangContext };
